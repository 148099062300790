.faq-main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding-top: 100px;
  .faq-container {
      width: 700px;
    max-width: 90vw;
    .hero {
        list-style: 3rem;
      .title {
        font-size: 3rem;
      }
      .subtitle {
        font-size: 1.5rem;

      }
    }
  }
}
