$color1: #fff;
$color2: rgba(0, 0, 0, 0.7);

$circleSprite0: $pixel*5 0 $color2,
$pixel*6 0 $color2,
$pixel*7 0 $color2,
$pixel*8 0 $color2,
$pixel*9 0 $color2,
$pixel*10 $pixel*1 $color2,
$pixel*11 $pixel*1 $color2,
$pixel*12 $pixel*2 $color2,
$pixel*13 $pixel*3 $color2,
$pixel*13 $pixel*4 $color2,
$pixel*14 $pixel*5 $color2,
$pixel*14 $pixel*6 $color2,
$pixel*14 $pixel*7 $color2,
$pixel*14 $pixel*8 $color2,
$pixel*14 $pixel*9 $color2,
$pixel*13 $pixel*10 $color2,
$pixel*13 $pixel*11 $color2,
$pixel*12 $pixel*12 $color2,
$pixel*11 $pixel*13 $color2,
$pixel*10 $pixel*13 $color2,
$pixel*9 $pixel*14 $color2,
$pixel*8 $pixel*14 $color2,
$pixel*7 $pixel*14 $color2,
$pixel*6 $pixel*14 $color2,
$pixel*5 $pixel*14 $color2,
$pixel*4 $pixel*13 $color2,
$pixel*3 $pixel*13 $color2,
$pixel*2 $pixel*12 $color2,
$pixel*1 $pixel*11 $color2,
$pixel*1 $pixel*10 $color2,
0 $pixel*9 $color2,
0 $pixel*8 $color2,
0 $pixel*7 $color2,
0 $pixel*6 $color2,
0 $pixel*5 $color2,
$pixel*1 $pixel*4 $color2,
$pixel*1 $pixel*3 $color2,
$pixel*2 $pixel*2 $color2,
$pixel*3 $pixel*1 $color2,
$pixel*4 $pixel*1 $color2,
$pixel*7 $pixel*1 $color1,
$pixel*8 $pixel*1 $color1,
$pixel*9 $pixel*1 $color1,
$pixel*7 $pixel*2 $color1,
$pixel*8 $pixel*2 $color1,
$pixel*9 $pixel*2 $color1,
$pixel*10 $pixel*2 $color1,
$pixel*11 $pixel*2 $color1,
$pixel*7 $pixel*3 $color1,
$pixel*8 $pixel*3 $color1,
$pixel*9 $pixel*3 $color1,
$pixel*10 $pixel*3 $color1,
$pixel*11 $pixel*3 $color1,
$pixel*12 $pixel*3 $color1,
$pixel*7 $pixel*4 $color1,
$pixel*8 $pixel*4 $color1,
$pixel*9 $pixel*4 $color1,
$pixel*10 $pixel*4 $color1,
$pixel*11 $pixel*4 $color1,
$pixel*12 $pixel*4 $color1,
$pixel*7 $pixel*5 $color1,
$pixel*8 $pixel*5 $color1,
$pixel*9 $pixel*5 $color1,
$pixel*10 $pixel*5 $color1,
$pixel*11 $pixel*5 $color1,
$pixel*12 $pixel*5 $color1,
$pixel*13 $pixel*5 $color1,
$pixel*7 $pixel*6 $color1,
$pixel*8 $pixel*6 $color1,
$pixel*9 $pixel*6 $color1,
$pixel*10 $pixel*6 $color1,
$pixel*11 $pixel*6 $color1,
$pixel*12 $pixel*6 $color1,
$pixel*13 $pixel*6 $color1,
$pixel*7 $pixel*7 $color1,
$pixel*8 $pixel*7 $color1,
$pixel*9 $pixel*7 $color1,
$pixel*10 $pixel*7 $color1,
$pixel*11 $pixel*7 $color1,
$pixel*12 $pixel*7 $color1,
$pixel*13 $pixel*7 $color1,
$pixel*1 $pixel*7 $color1,
$pixel*2 $pixel*7 $color1,
$pixel*3 $pixel*7 $color1,
$pixel*4 $pixel*7 $color1,
$pixel*5 $pixel*7 $color1,
$pixel*6 $pixel*7 $color1,
$pixel*1 $pixel*8 $color1,
$pixel*2 $pixel*8 $color1,
$pixel*3 $pixel*8 $color1,
$pixel*4 $pixel*8 $color1,
$pixel*5 $pixel*8 $color1,
$pixel*6 $pixel*8 $color1,
$pixel*7 $pixel*8 $color1,
$pixel*1 $pixel*9 $color1,
$pixel*2 $pixel*9 $color1,
$pixel*3 $pixel*9 $color1,
$pixel*4 $pixel*9 $color1,
$pixel*5 $pixel*9 $color1,
$pixel*6 $pixel*9 $color1,
$pixel*7 $pixel*9 $color1,
$pixel*2 $pixel*10 $color1,
$pixel*3 $pixel*10 $color1,
$pixel*4 $pixel*10 $color1,
$pixel*5 $pixel*10 $color1,
$pixel*6 $pixel*10 $color1,
$pixel*7 $pixel*10 $color1,
$pixel*2 $pixel*11 $color1,
$pixel*3 $pixel*11 $color1,
$pixel*4 $pixel*11 $color1,
$pixel*5 $pixel*11 $color1,
$pixel*6 $pixel*11 $color1,
$pixel*7 $pixel*11 $color1,
$pixel*3 $pixel*12 $color1,
$pixel*4 $pixel*12 $color1,
$pixel*5 $pixel*12 $color1,
$pixel*6 $pixel*12 $color1,
$pixel*7 $pixel*12 $color1,
$pixel*5 $pixel*13 $color1,
$pixel*6 $pixel*13 $color1,
$pixel*7 $pixel*13 $color1,
$pixel*5 $pixel*1 $color1,
$pixel*6 $pixel*1 $color1,
$pixel*3 $pixel*2 $color1,
$pixel*4 $pixel*2 $color1,
$pixel*5 $pixel*2 $color1,
$pixel*6 $pixel*2 $color1,
$pixel*2 $pixel*3 $color1,
$pixel*3 $pixel*3 $color1,
$pixel*4 $pixel*3 $color1,
$pixel*5 $pixel*3 $color1,
$pixel*6 $pixel*3 $color1,
$pixel*2 $pixel*4 $color1,
$pixel*3 $pixel*4 $color1,
$pixel*4 $pixel*4 $color1,
$pixel*5 $pixel*4 $color1,
$pixel*6 $pixel*4 $color1,
$pixel*1 $pixel*5 $color1,
$pixel*2 $pixel*5 $color1,
$pixel*3 $pixel*5 $color1,
$pixel*4 $pixel*5 $color1,
$pixel*5 $pixel*5 $color1,
$pixel*6 $pixel*5 $color1,
$pixel*1 $pixel*6 $color1,
$pixel*2 $pixel*6 $color1,
$pixel*3 $pixel*6 $color1,
$pixel*4 $pixel*6 $color1,
$pixel*5 $pixel*6 $color1,
$pixel*6 $pixel*6 $color1,
$pixel*8 $pixel*8 $color1,
$pixel*9 $pixel*8 $color1,
$pixel*10 $pixel*8 $color1,
$pixel*11 $pixel*8 $color1,
$pixel*12 $pixel*8 $color1,
$pixel*13 $pixel*8 $color1,
$pixel*8 $pixel*9 $color1,
$pixel*9 $pixel*9 $color1,
$pixel*10 $pixel*9 $color1,
$pixel*11 $pixel*9 $color1,
$pixel*12 $pixel*9 $color1,
$pixel*13 $pixel*9 $color1,
$pixel*8 $pixel*10 $color1,
$pixel*9 $pixel*10 $color1,
$pixel*10 $pixel*10 $color1,
$pixel*11 $pixel*10 $color1,
$pixel*12 $pixel*10 $color1,
$pixel*8 $pixel*11 $color1,
$pixel*9 $pixel*11 $color1,
$pixel*10 $pixel*11 $color1,
$pixel*11 $pixel*11 $color1,
$pixel*12 $pixel*11 $color1,
$pixel*8 $pixel*12 $color1,
$pixel*9 $pixel*12 $color1,
$pixel*10 $pixel*12 $color1,
$pixel*11 $pixel*12 $color1,
$pixel*8 $pixel*13 $color1,
$pixel*9 $pixel*13 $color1;

$circleSprite1: $pixel*7 $pixel*1 $color2,
$pixel*8 $pixel*1 $color2,
$pixel*9 $pixel*1 $color2,
$pixel*7 $pixel*2 $color2,
$pixel*8 $pixel*2 $color2,
$pixel*9 $pixel*2 $color2,
$pixel*10 $pixel*2 $color2,
$pixel*11 $pixel*2 $color2,
$pixel*7 $pixel*3 $color2,
$pixel*8 $pixel*3 $color2,
$pixel*9 $pixel*3 $color2,
$pixel*10 $pixel*3 $color2,
$pixel*11 $pixel*3 $color2,
$pixel*12 $pixel*3 $color2,
$pixel*7 $pixel*4 $color2,
$pixel*8 $pixel*4 $color2,
$pixel*9 $pixel*4 $color2,
$pixel*10 $pixel*4 $color2,
$pixel*11 $pixel*4 $color2,
$pixel*12 $pixel*4 $color2,
$pixel*7 $pixel*5 $color2,
$pixel*8 $pixel*5 $color2,
$pixel*9 $pixel*5 $color2,
$pixel*10 $pixel*5 $color2,
$pixel*11 $pixel*5 $color2,
$pixel*12 $pixel*5 $color2,
$pixel*13 $pixel*5 $color2,
$pixel*7 $pixel*6 $color2,
$pixel*8 $pixel*6 $color2,
$pixel*9 $pixel*6 $color2,
$pixel*10 $pixel*6 $color2,
$pixel*11 $pixel*6 $color2,
$pixel*12 $pixel*6 $color2,
$pixel*13 $pixel*6 $color2,
$pixel*7 $pixel*7 $color2,
$pixel*8 $pixel*7 $color2,
$pixel*9 $pixel*7 $color2,
$pixel*10 $pixel*7 $color2,
$pixel*11 $pixel*7 $color2,
$pixel*12 $pixel*7 $color2,
$pixel*13 $pixel*7 $color2,
$pixel*1 $pixel*7 $color2,
$pixel*2 $pixel*7 $color2,
$pixel*3 $pixel*7 $color2,
$pixel*4 $pixel*7 $color2,
$pixel*5 $pixel*7 $color2,
$pixel*6 $pixel*7 $color2,
$pixel*1 $pixel*8 $color2,
$pixel*2 $pixel*8 $color2,
$pixel*3 $pixel*8 $color2,
$pixel*4 $pixel*8 $color2,
$pixel*5 $pixel*8 $color2,
$pixel*6 $pixel*8 $color2,
$pixel*7 $pixel*8 $color2,
$pixel*1 $pixel*9 $color2,
$pixel*2 $pixel*9 $color2,
$pixel*3 $pixel*9 $color2,
$pixel*4 $pixel*9 $color2,
$pixel*5 $pixel*9 $color2,
$pixel*6 $pixel*9 $color2,
$pixel*7 $pixel*9 $color2,
$pixel*2 $pixel*10 $color2,
$pixel*3 $pixel*10 $color2,
$pixel*4 $pixel*10 $color2,
$pixel*5 $pixel*10 $color2,
$pixel*6 $pixel*10 $color2,
$pixel*7 $pixel*10 $color2,
$pixel*2 $pixel*11 $color2,
$pixel*3 $pixel*11 $color2,
$pixel*4 $pixel*11 $color2,
$pixel*5 $pixel*11 $color2,
$pixel*6 $pixel*11 $color2,
$pixel*7 $pixel*11 $color2,
$pixel*3 $pixel*12 $color2,
$pixel*4 $pixel*12 $color2,
$pixel*5 $pixel*12 $color2,
$pixel*6 $pixel*12 $color2,
$pixel*7 $pixel*12 $color2,
$pixel*5 $pixel*13 $color2,
$pixel*6 $pixel*13 $color2,
$pixel*7 $pixel*13 $color2;

$circleSprite2: $pixel*7 $pixel*7 $color2,
$pixel*6 $pixel*8 $color2,
$pixel*6 $pixel*9 $color2,
$pixel*5 $pixel*10 $color2,
$pixel*5 $pixel*11 $color2,
$pixel*4 $pixel*12 $color2,
$pixel*6 $pixel*6 $color2,
$pixel*5 $pixel*6 $color2,
$pixel*4 $pixel*5 $color2,
$pixel*3 $pixel*5 $color2,
$pixel*2 $pixel*4 $color2,
$pixel*8 $pixel*6 $color2,
$pixel*8 $pixel*5 $color2,
$pixel*9 $pixel*4 $color2,
$pixel*9 $pixel*3 $color2,
$pixel*10 $pixel*2 $color2,
$pixel*8 $pixel*8 $color2,
$pixel*9 $pixel*8 $color2,
$pixel*10 $pixel*9 $color2,
$pixel*11 $pixel*9 $color2,
$pixel*12 $pixel*10 $color2,
$pixel*1 $pixel*5 $color2,
$pixel*2 $pixel*5 $color2,
$pixel*1 $pixel*6 $color2,
$pixel*2 $pixel*6 $color2,
$pixel*3 $pixel*6 $color2,
$pixel*4 $pixel*6 $color2,
$pixel*1 $pixel*7 $color2,
$pixel*2 $pixel*7 $color2,
$pixel*3 $pixel*7 $color2,
$pixel*4 $pixel*7 $color2,
$pixel*5 $pixel*7 $color2,
$pixel*6 $pixel*7 $color2,
$pixel*1 $pixel*8 $color2,
$pixel*2 $pixel*8 $color2,
$pixel*3 $pixel*8 $color2,
$pixel*4 $pixel*8 $color2,
$pixel*5 $pixel*8 $color2,
$pixel*1 $pixel*9 $color2,
$pixel*2 $pixel*9 $color2,
$pixel*3 $pixel*9 $color2,
$pixel*4 $pixel*9 $color2,
$pixel*5 $pixel*9 $color2,
$pixel*2 $pixel*10 $color2,
$pixel*3 $pixel*10 $color2,
$pixel*4 $pixel*10 $color2,
$pixel*2 $pixel*11 $color2,
$pixel*3 $pixel*11 $color2,
$pixel*4 $pixel*11 $color2,
$pixel*3 $pixel*12 $color2,
$pixel*11 $pixel*2 $color2,
$pixel*10 $pixel*3 $color2,
$pixel*11 $pixel*3 $color2,
$pixel*12 $pixel*3 $color2,
$pixel*10 $pixel*4 $color2,
$pixel*11 $pixel*4 $color2,
$pixel*12 $pixel*4 $color2,
$pixel*9 $pixel*5 $color2,
$pixel*10 $pixel*5 $color2,
$pixel*11 $pixel*5 $color2,
$pixel*12 $pixel*5 $color2,
$pixel*13 $pixel*5 $color2,
$pixel*9 $pixel*6 $color2,
$pixel*10 $pixel*6 $color2,
$pixel*11 $pixel*6 $color2,
$pixel*12 $pixel*6 $color2,
$pixel*13 $pixel*6 $color2,
$pixel*8 $pixel*7 $color2,
$pixel*9 $pixel*7 $color2,
$pixel*10 $pixel*7 $color2,
$pixel*11 $pixel*7 $color2,
$pixel*12 $pixel*7 $color2,
$pixel*13 $pixel*7 $color2,
$pixel*10 $pixel*8 $color2,
$pixel*11 $pixel*8 $color2,
$pixel*12 $pixel*8 $color2,
$pixel*13 $pixel*8 $color2,
$pixel*12 $pixel*9 $color2,
$pixel*13 $pixel*9 $color2;
$circleSprite3: $pixel*1 $pixel*5 $color2,
$pixel*1 $pixel*6 $color2,
$pixel*1 $pixel*7 $color2,
$pixel*1 $pixel*8 $color2,
$pixel*1 $pixel*9 $color2,
$pixel*2 $pixel*3 $color2,
$pixel*2 $pixel*4 $color2,
$pixel*2 $pixel*5 $color2,
$pixel*2 $pixel*6 $color2,
$pixel*2 $pixel*7 $color2,
$pixel*2 $pixel*8 $color2,
$pixel*2 $pixel*9 $color2,
$pixel*2 $pixel*10 $color2,
$pixel*2 $pixel*11 $color2,
$pixel*3 $pixel*3 $color2,
$pixel*3 $pixel*4 $color2,
$pixel*3 $pixel*5 $color2,
$pixel*3 $pixel*6 $color2,
$pixel*3 $pixel*7 $color2,
$pixel*3 $pixel*8 $color2,
$pixel*3 $pixel*9 $color2,
$pixel*3 $pixel*10 $color2,
$pixel*3 $pixel*11 $color2,
$pixel*7 $pixel*7 $color2,
$pixel*6 $pixel*6 $color2,
$pixel*5 $pixel*5 $color2,
$pixel*4 $pixel*4 $color2,
$pixel*6 $pixel*8 $color2,
$pixel*5 $pixel*9 $color2,
$pixel*4 $pixel*10 $color2,
$pixel*4 $pixel*9 $color2,
$pixel*4 $pixel*8 $color2,
$pixel*4 $pixel*7 $color2,
$pixel*4 $pixel*6 $color2,
$pixel*4 $pixel*5 $color2,
$pixel*5 $pixel*6 $color2,
$pixel*5 $pixel*7 $color2,
$pixel*5 $pixel*8 $color2,
$pixel*6 $pixel*7 $color2,
$pixel*8 $pixel*6 $color2,
$pixel*9 $pixel*5 $color2,
$pixel*10 $pixel*4 $color2,
$pixel*11 $pixel*3 $color2,
$pixel*12 $pixel*3 $color2,
$pixel*8 $pixel*8 $color2,
$pixel*9 $pixel*9 $color2,
$pixel*10 $pixel*10 $color2,
$pixel*11 $pixel*11 $color2,
$pixel*8 $pixel*7 $color2,
$pixel*9 $pixel*6 $color2,
$pixel*9 $pixel*7 $color2,
$pixel*9 $pixel*8 $color2,
$pixel*10 $pixel*5 $color2,
$pixel*10 $pixel*6 $color2,
$pixel*10 $pixel*7 $color2,
$pixel*10 $pixel*8 $color2,
$pixel*10 $pixel*9 $color2,
$pixel*11 $pixel*4 $color2,
$pixel*11 $pixel*5 $color2,
$pixel*11 $pixel*6 $color2,
$pixel*11 $pixel*7 $color2,
$pixel*11 $pixel*8 $color2,
$pixel*11 $pixel*9 $color2,
$pixel*11 $pixel*10 $color2,
$pixel*12 $pixel*4 $color2,
$pixel*12 $pixel*5 $color2,
$pixel*12 $pixel*6 $color2,
$pixel*12 $pixel*7 $color2,
$pixel*12 $pixel*8 $color2,
$pixel*12 $pixel*9 $color2,
$pixel*12 $pixel*10 $color2,
$pixel*12 $pixel*11 $color2,
$pixel*13 $pixel*5 $color2,
$pixel*13 $pixel*6 $color2,
$pixel*13 $pixel*7 $color2,
$pixel*13 $pixel*8 $color2,
$pixel*13 $pixel*9 $color2;

.loader.circle {
  height: $h;
  width: $pixel*15;
  position: relative;
  margin: auto;

  div {
    position: absolute;
    top: $pixel*3.5;
    left: 0;
    width: $pixel;
    height: $pixel;
    box-shadow: $circleSprite0;
    z-index: 0;

    +div {
      box-shadow: $circleSprite1;
      z-index: 1;

      @if ($animOn==1) {
        animation: spriteSpin $anim4Dur steps(1) infinite;
      }
    }
  }
}


@keyframes spriteSpin {
  from {
    box-shadow: $circleSprite1;
  }

  12.5% {
    box-shadow: $circleSprite2;
  }

  25% {
    box-shadow: $circleSprite3;
  }

  37.5% {
    box-shadow: $circleSprite2;
    transform: scale(-1, 1) translate($pixel*-14, 0);
  }

  50% {
    box-shadow: $circleSprite1;
    transform: scale(-1, 1) translate($pixel*-14, 0);
  }

  62.5% {
    box-shadow: $circleSprite2;
    transform: scale(1, 1) translate($pixel*14, 0) rotate(90deg);
  }

  75% {
    box-shadow: $circleSprite3;
    transform: scale(1, 1) translate($pixel*14, 0) rotate(90deg);
  }

  87.5% {
    box-shadow: $circleSprite2;
    transform: scale(-1, 1) translate(0, 0) rotate(90deg);
  }

  to {
    box-shadow: $circleSprite1;
  }
}
