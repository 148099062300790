.create-pool-main-container {
  display: flex;
  padding-top: 50px;
  flex-wrap: wrap;
  gap: 30px;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 3rem;
    line-height: 1rem;
  }

  .create-pool-form-container {
    display: flex;
    padding-top: 50px;
    flex-wrap: wrap;
    gap: 30px;
    flex-direction: row;
    justify-content: center;

    .info-card {
      width: 500px;
      max-width: 100vw;

      >.ant-card-body {

        line-height: 1;
        text-align: center;
      }

      h1 {
        font-size: 2.5rem;
      }

      p {
        font-size: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .accent {
        font-size: 1.2em;
        font-weight: 700;
        color: green;
      }
    }

    .create-pool-card {
      width: 500px;
      max-width: 100vw;
    }
  }
}
