.entry-form-container {
  width: 100%;

  .option-container {
    width: 100%;
    max-width: 1000px;
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .selection-title {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .selection-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .selection-item {
      background: green;
      font-size: 2.5rem;
      width: 70px;
      height: 70px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      box-shadow: 4px 4px 0px white;

      &.gray {
        background: gray;
      }
    }
  }

  .submission-container {
    margin-top: 20px;
  }
}

.game-result {
  .title {
    font-size: 2rem;
  }

  .prize {
    font-size: 4rem;
  }

  .number-title {
    font-size: 1.5rem;
  }
}

.faq-option-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
