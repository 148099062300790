html,
body {
    color: #fff;
    line-height: 1.5714285714285714;
}

html,
body,
* {
    font-family: "VT323", 'DotGothic16', sans-serif !important;
}

.ant-btn-lg {
    height: auto !important;
}

.stay-degen-header-logo {
    cursor: pointer;
    font-size: 2rem;
    padding-inline: 10px;
}

.ant-input[disabled] {
    color: rgba(255, 255, 255, 0.7) !important;
    border-color: #424242 !important;
}
