.hero-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding-block: 100px;
  .dejohn{
    width: 100px;
  }
  .title {
    font-size: 5rem;
    text-shadow: -4px 4px 0px green;
    line-height: 3rem;
  }
  .slogan {
    font-size: 2rem;
    text-shadow: -1px 1px 0px green;
  }

  .stats-container {
    display: flex;
    gap: 10px;
    margin-top: 40px;
    align-items: center;
    .stat {
      display: flex;
      flex-direction: column;
      align-items: center;
      .label {
        font-size: 1rem;
      }
      .value {
        font-size: 1.3rem;
        color: green;
        line-height: 1rem;
        text-shadow: -0.5px 0.5px 0px white;
        .sol-icon {
          width: 13px;
        }
      }
    }
    @media screen and (min-width: 600px) {
      gap: 30px;
      .stat {
        display: flex;
        flex-direction: column;
        align-items: center;
        .label {
          font-size: 2rem;
        }
        .value {
          font-size: 3rem;
          color: green;
          line-height: 2rem;
          text-shadow: -0.5px 0.5px 0px white;
          .sol-icon {
            width: 20px;
          }
        }
      }
    }
  }
  .faq-leader {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
}
