.preview-pools-main-container {
  .pools-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }
}

.pool-type-tabs {
  min-width: 50vw;
  max-width: 700px;
  width: 100%;
  text-transform: uppercase;

  &.ant-tabs {
    margin: auto;
    .ant-tabs-tab {
      font-size: 1.25em;
      width: 100%;
      justify-content: center;
    }
    >.ant-tabs-nav {
      margin-bottom: 0;
      .ant-tabs-nav-list {
        flex: 1;
      }
    }
  }
}
