.degen-footer {
  margin-top: 40px;
  text-align: center;

  .twitter-link {
    display: inline-block;
    border-radius: 30px;
    background-color: #fff;
    height: 30px;
    margin-left: 15px;
    vertical-align: middle;

    .anticon {
      font-size: 30px;
    }
  }
}
