.loader.coin {
	margin: auto;
	vertical-align: middle;
	display: inline-block;
  background: url('https://i.imgur.com/OZChImx.png') left center;
  animation: play 0.8s steps(6) infinite;
}

@keyframes play {
  100% {
    background-position: -240px;
  }
}
