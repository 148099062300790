.main-filter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;

  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 0 20px;
    flex-direction: column;

    .ant-form-item {
      margin-bottom: 24px;

      &:last-child {
        margin-inline-end: 0;
      }

      .ant-form-item-label {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .ant-checkbox-wrapper {
    margin-left: 10px;
  }
  .main-filter-container .form-container {
    .styled-form {
      justify-content: space-between;
      .ant-form-item {
        margin: 0 0 24px;
        width: calc(50% - 10px);
        .ant-input-number, .ant-input-number-group-wrapper {
          width: 100% !important;
        }
      }
    }
  }
}
