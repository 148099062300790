.pool-stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-top: 50px;
  width: 100%;
  .by {
    margin-top: 40px;
    color: rgb(159, 159, 159);
    font-size: 2rem;
  }
  .pool-size {
    .pool {
      font-size: 4rem;
      color: green;
      text-shadow: -0.5px 0.5px 0px white;
      .sol-icon {
        width: 30px;
        margin-right: 15px;
      }
      /* On screens that are 600px or less, set the background color to olive */
      @media screen and (min-width: 600px) {
        font-size: 8rem;
        line-height: 4rem;
        text-shadow: -2px 2px 0px white;
        margin-block: 10px;
        .sol-icon {
          width: 50px;
          margin-right: 15px;
        }
      }
    }

    .x {
      font-size: 3rem;
      text-shadow: -2px 2px 0px green;
    }
  }
  .stats-container {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    align-items: center;
    .stat {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 1rem;
      }
      .value {
        font-size: 2rem;
        color: green;
        text-shadow: -0.5px 0.5px 0px white;
      }
    }
    @media screen and (min-width: 600px) {
      gap: 30px;
      .stat {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 2rem;
        }
        .value {
          font-size: 3rem;
          color: green;
          line-height: 2rem;
          text-shadow: -0.5px 0.5px 0px white;
        }
      }
    }
  }
}
