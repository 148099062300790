.main-page-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
    .actions-container {
        display: flex;
        align-items: center;
        gap: 1em;
        > a {
            display: block;
        }
        .ant-btn-default {
            background-color: unset;
        }
    }
}

header {
    padding-inline: 10px !important;
}

.ant-layout .ant-layout-header {
    height: auto;
}
