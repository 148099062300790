.pool-listview-container {
  * .pool-size {
    font-size: 1.5rem;
    text-shadow: -2px 2px 0px rgb(0, 0, 0);
    line-height: 1rem;
    display: flex;
    color: rgb(0, 160, 0);
    align-items: center;
    justify-content: flex-start;

    .sol-icon {
      width: 15px;
      margin-right: 15px;
    }
  }
  .pool-title {
    margin-top: 5px;
    text-transform: none;
  }

  * .entry-price {
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    color: white;
    align-items: center;
    justify-content: flex-start;

    .sol-icon {
      width: 10px;
      margin-right: 10px;
    }
  }
  * .x {
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    color: rgb(0, 160, 0);
    align-items: center;
    justify-content: flex-start;

    .x-text {
      font-size: 1rem;
      color: white;
    }
  }
}

.pools-pagination {
  text-align: right;
  margin: 20px 0;
}
